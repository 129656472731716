/* In order to use the new (and not yet fully adopted) units
that take things like iOS browser bars into account, this CSS
file is defined and imported in the various session views (StudentView,
IndividualSession, and TeacherPreviewSession). It allows us to define
a fallback for min-height in cases that the "svh" units are not supported. */
#sessionContainer {
  min-height: 100vh;
  min-height: 100svh;
}

.fullHeight {
  min-height: 100vh;
  min-height: 100svh;

  max-height: 100vh;
  max-height: 100svh;
}
