/***** GOOPER SEMI BOLD *****/
@font-face {
  font-family: "Gooper";
  font-weight: 600;
  font-style: normal;
  src: url("adminFonts/Gooper/Gooper7-SemiBold.woff2") format("woff2"),
    url("adminFonts/Gooper/Gooper7-SemiBold.woff") format("woff");
}

/***** GOOPER BOLD *****/
@font-face {
  font-family: "Gooper";
  font-weight: 700;
  font-style: normal;
  src: url("adminFonts/Gooper/Gooper7-Bold.woff2") format("woff2"),
    url("adminFonts/Gooper/Gooper7-Bold.woff") format("woff");
}

/***** TTCommons Regular *****/
@font-face {
  font-family: "TTCommons";
  font-weight: 400;
  font-style: normal;
  src: url("adminFonts/TTCommons/tt_commons_classic_regular-webfont.woff2")
      format("woff2"),
    url("adminFonts/TTCommons/tt_commons_classic_regular-webfont.woff")
      format("woff");
}

/***** TTCommons Regular Italic *****/
@font-face {
  font-family: "TTCommons";
  font-weight: 400;
  font-style: italic;
  src: url("adminFonts/TTCommons/tt_commons_classic_italic-webfont.woff2")
      format("woff2"),
    url("adminFonts/TTCommons/tt_commons_classic_italic-webfont.woff")
      format("woff");
}

/***** TTCommons Medium *****/
@font-face {
  font-family: "TTCommons";
  font-weight: 500;
  font-style: normal;
  src: url("adminFonts/TTCommons/tt_commons_classic_medium-webfont.woff2")
      format("woff2"),
    url("adminFonts/TTCommons/tt_commons_classic_medium-webfont.woff")
      format("woff");
}

/***** TTCommons Demibold *****/
@font-face {
  font-family: "TTCommons";
  font-weight: 600;
  font-style: normal;
  src: url("adminFonts/TTCommons/tt_commons_classic_demibold-webfont.woff2")
      format("woff2"),
    url("adminFonts/TTCommons/tt_commons_classic_demibold-webfont.woff")
      format("woff");
}

/***** TTCommons Demibold Italic *****/
@font-face {
  font-family: "TTCommons";
  font-weight: 600;
  font-style: italic;
  src: url("adminFonts/TTCommons/tt_commons_classic_demibold_italic-webfont.woff2")
      format("woff2"),
    url("adminFonts/TTCommons/tt_commons_classic_demibold_italic-webfont.woff")
      format("woff");
}

/***** TTCommons Bold  *****/
@font-face {
  font-family: "TTCommons";
  font-weight: 700;
  font-style: normal;
  src: url("adminFonts/TTCommons/tt_commons_classic_bold-webfont.woff2")
      format("woff2"),
    url("adminFonts/TTCommons/tt_commons_classic_bold-webfont.woff")
      format("woff");
}

/***** Cabin Sketch  *****/
@font-face {
  font-family: "Cabin Sketch";
  font-weight: 700;
  font-style: normal;
  src: url("adminFonts/CabinSketch/CabinSketch-Regular.woff") format("woff2"),
    url("adminFonts/CabinSketch/CabinSketch-Regular.woff") format("woff");
}
